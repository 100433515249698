.TestContainer {
    display: flex;
    justify-content: center;
    width: 99%;
    margin: 5px 5px 0 5px;
   }

.TestContainer__content{
    padding-top: 10px;
}

.wrapper__answer {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}
.create__answer {
    padding: 5px;
    width: 50%;
}

.wrapper__number_answer {
    display: flex;
    justify-content: space-between;
    column-gap: 5px;
}

.inner__answer {
    display: flex;
    justify-content: space-between;
}

.wrapper__area {
    width: 50%;
}
.TestContainer__btns {
    /*padding: 15px 0;*/
    /*margin-top: 10px;*/
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.TestContainer__inputs {
  display: flex;
    flex-direction: column;
  row-gap: 15px;
}

@media screen and (max-width: 900px) {
 .TestContainer {
     margin: 0;
     margin-top: 5px;
     width: 100%;
 }
}