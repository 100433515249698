.test-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 10px;
    align-items: center;
}

.test-item {
    margin-bottom: 10px;
}

@media screen and (max-width: 900px) {
    .test-wrapper {
        width: 100%;
    }
}