.card-redact {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.input-age {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.input-gender {
  display: flex;
  justify-content: space-between;
  width: 50%;
  flex-direction: row;
}

@media screen and (max-width: 900px) {
}
