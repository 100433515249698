.task-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.tasks {
    width: 100%
}

.task {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 1px solid whitesmoke;
    border-radius: 20px;
    width: 100%;
    padding: 10px;
    text-align: center;
    align-items: center;
    margin-bottom: 5px;
}

.buttons {
    display: flex;
    flex-direction: row;
}

.button-element {
    margin-right: 10px;
    cursor: pointer;

}

@media screen and (max-width: 900px) {
    .task {
        width: 100%;
    }
}