.taskLists__Content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 20px;
    margin-right: 20px;
    width: 100%;
    column-gap: 20px;
}

.taskLists{
    height: calc(100vh - 100px);
    padding-top: 30px;
}

.taskLists__content__cardl {
    margin-left: 10px;
}

@media screen and (max-width: 900px) {
    .taskLists__Content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 0;
        margin-right: 0;
    }

    .taskLists {
        padding-top: 5px;
    }

    .taskLists__content__cardl {
        margin-top: 10px;
        margin-left: 0;
    }

}