.main-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 100%;
    column-gap: 100px;
}

.brand-info {
    width: 30%;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: auto 0;
}

.brand-description {
    font-size: 18px;

}

.tests {
    width: 40%;
    margin: auto 0;
}

@media screen and (max-width: 900px) {
    .main-wrapper {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .brand-info {
        width: 100%;
    }

    .tests {
        margin-top: 10px;
        margin-bottom: 5px;
        width: 100%;
    }
}
